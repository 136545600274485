<template>
    <v-form v-if="user" class="space-y-8" @submit.prevent="submitUserForm(userForm.user_id, userForm)">
        <v-form-group v-if="games" :permissions="['users.update']">
            <v-form-label>Games</v-form-label>

            <v-form-group class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3">
                <template v-for="(game, gameIndex) in games">
                    <v-form-checkbox :key="'game_' + gameIndex" v-model="userForm.games" :value="game.game_id" :disabled="isLoading || !$me.hasPermission('users.update')">
                        <span class="block">
                            {{ game.name }}
                        </span>
                    </v-form-checkbox>
                </template>
            </v-form-group>
        </v-form-group>

        <v-form-group class="flex w-full justify-end">
            <v-button class="w-full sm:w-auto" color="primary" text-alignment="center" :disabled="isLoading || !($me.hasPermission('users.update'))">
                Save Changes
            </v-button>
        </v-form-group>
    </v-form>
</template>

<script>
import Collect from "collect.js";
import GameService from "@/services/modules/game-service";
import UserService from "@/services/modules/user-service";

export default {
    name: "UserEditGamesForm",
    props: {
        user: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            /**
             * Game Service
             *
             * @todo Write a description.
             */
            gameService: GameService,

            /**
             * User Service
             *
             * @todo Write a description.
             */
            userService: UserService,

            /**
             * Games
             *
             * @todo Write a description.
             */
            games: {},

            /**
             * User Form
             *
             * @todo Write a description.
             */
            userForm: {
                user_id: null,
                games: [],
            },
        };
    },
    watch: {
        user: {
            handler({ user_id: userId = null, games = [] }) {
                this.userForm = {
                    user_id: userId,
                    games: this.parseGames(games),
                };
            },
            immediate: true,
            deep: true,
        },
    },
    created() {
        this.gameService.index()
            .then((response) => {
                this.games = response.data.data;
            });
    },
    methods: {
        /**
         * Submit User Form
         *
         * @todo Write a description.
         */
        submitUserForm(userId, payload) {
            this.toggleLoading();

            this.userService.updateGames(userId, payload)
                .then((response) => {
                    this.$emit("user-updated", response);
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.toggleLoading();
                });
        },

        /**
         * Parse Games
         *
         * @todo Write a description.
         */
        parseGames(games) {
            return Collect(games).pluck("game_id").toArray();
        },
    },
};
</script>
